import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { ContentFrame, ContentFooter } from "../components/ContentFrame";
import HomeTile from "../components/HomeTile";
import { debounce } from "lodash";

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.leftCol = React.createRef();
    this.middleCol = React.createRef();
    this.rightCol = React.createRef();
    this.scrollTarget = null;
    this.state = {
      columns: 3
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = e => {
    this.setState({
      columns: window.innerWidth > 1020 ? 3 : window.innerWidth > 600 ? 2 : 1
    });
  };

  initScroll = () => {
    this.leftStart = this.leftCol.current.scrollTop;
    if (this.middleCol.current) {
      this.middleStart = this.middleCol.current.scrollTop;
    }
    this.rightStart = this.rightCol.current.scrollTop;
  };

  onScrollLeft = e => {
    if (this.scrollTarget && this.scrollTarget !== e.currentTarget) return;

    if (!this.scrollTarget) {
      this.initScroll();
      this.scrollTarget = e.currentTarget;
    }

    const scrollDiff = e.currentTarget.scrollTop - this.leftStart;

    if (this.middleCol.current) {
      this.middleCol.current.scrollTop = this.middleStart + scrollDiff * 0.75;
      this.rightCol.current.scrollTop = this.rightStart + scrollDiff * 0.5;
    } else {
      this.rightCol.current.scrollTop = this.rightStart + scrollDiff * 0.75;
    }

    this.stopScroll();
  };

  onScrollMiddle = e => {
    if (this.scrollTarget && this.scrollTarget !== e.currentTarget) return;

    if (!this.scrollTarget) {
      this.initScroll();
      this.scrollTarget = e.currentTarget;
    }

    const scrollDiff = e.currentTarget.scrollTop - this.middleStart;

    this.leftCol.current.scrollTop = this.leftStart + scrollDiff * 0.75;
    this.rightCol.current.scrollTop = this.rightStart + scrollDiff * 0.75;

    this.stopScroll();
  };

  onScrollRight = e => {
    if (this.scrollTarget && this.scrollTarget !== e.currentTarget) return;

    if (!this.scrollTarget) {
      this.initScroll();
      this.scrollTarget = e.currentTarget;
    }

    const scrollDiff = e.currentTarget.scrollTop - this.rightStart;

    if (this.middleCol.current) {
      this.leftCol.current.scrollTop = this.leftStart + scrollDiff * 0.5;
      this.middleCol.current.scrollTop = this.middleStart + scrollDiff * 0.75;
    } else {
      this.leftCol.current.scrollTop = this.leftStart + scrollDiff * 0.75;
    }

    this.stopScroll();
  };

  stopScroll = debounce(() => {
    this.scrollTarget = null;
  }, 300);

  render() {
    const { data } = this.props;
    const { edges: projects } = data.allMarkdownRemark;
    const { columns } = this.state;

    return (
      <Layout>
        <ContentFrame>
          {columns === 3 ? (
            <div className="Home__Columns Home__Columns--3">
              <div
                ref={this.leftCol}
                className="Home__Column"
                onScroll={this.onScrollLeft}
              >
                {projects
                  .filter((_, i) => i % 3 === 0)
                  .map(({ node: project }) => (
                    <Link key={project.fields.slug} to={project.fields.slug}>
                      <HomeTile
                        title={project.frontmatter.title}
                        src={project.frontmatter.image}
                        hoverSrc={project.frontmatter.imageHover}
                      />
                    </Link>
                  ))}
              </div>
              <div className="Home__Column__Spacer" />
              <div
                ref={this.middleCol}
                className="Home__Column"
                onScroll={this.onScrollMiddle}
              >
                {projects
                  .filter((_, i) => i % 3 === 1)
                  .map(({ node: project }) => (
                    <Link key={project.fields.slug} to={project.fields.slug}>
                      <HomeTile
                        title={project.frontmatter.title}
                        src={project.frontmatter.image}
                        hoverSrc={project.frontmatter.imageHover}
                      />
                    </Link>
                  ))}
              </div>
              <div className="Home__Column__Spacer" />
              <div
                ref={this.rightCol}
                className="Home__Column"
                onScroll={this.onScrollRight}
              >
                {projects
                  .filter((_, i) => i % 3 === 2)
                  .map(({ node: project }) => (
                    <Link key={project.fields.slug} to={project.fields.slug}>
                      <HomeTile
                        title={project.frontmatter.title}
                        src={project.frontmatter.image}
                        hoverSrc={project.frontmatter.imageHover}
                      />
                    </Link>
                  ))}
              </div>
            </div>
          ) : columns === 2 ? (
            <div className="Home__Columns Home__Columns--2">
              <div
                ref={this.leftCol}
                className="Home__Column"
                onScroll={this.onScrollLeft}
              >
                {projects
                  .filter((_, i) => i % 2 === 0)
                  .map(({ node: project }) => (
                    <Link key={project.fields.slug} to={project.fields.slug}>
                      <HomeTile
                        title={project.frontmatter.title}
                        src={project.frontmatter.image}
                        hoverSrc={project.frontmatter.imageHover}
                      />
                    </Link>
                  ))}
              </div>
              <div className="Home__Column__Spacer" />
              <div
                ref={this.rightCol}
                className="Home__Column"
                onScroll={this.onScrollRight}
              >
                {projects
                  .filter((_, i) => i % 2 === 1)
                  .map(({ node: project }) => (
                    <Link key={project.fields.slug} to={project.fields.slug}>
                      <HomeTile
                        title={project.frontmatter.title}
                        src={project.frontmatter.image}
                        hoverSrc={project.frontmatter.imageHover}
                      />
                    </Link>
                  ))}
              </div>
            </div>
          ) : (
            <div className="Home__Columns Home__Columns--1">
              <div ref={this.leftCol} className="Home__Column">
                {projects.map(({ node: project }) => (
                  <Link key={project.fields.slug} to={project.fields.slug}>
                    <HomeTile
                      title={project.frontmatter.title}
                      src={project.frontmatter.image}
                      hoverSrc={project.frontmatter.imageHover}
                    />
                  </Link>
                ))}
              </div>
            </div>
          )}
        </ContentFrame>
        <ContentFooter />
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___sortOrder] }
      filter: {
        frontmatter: {
          templateKey: { eq: "project-page" }
          type: { eq: "creative" }
        }
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            image
            imageHover
          }
        }
      }
    }
  }
`;
