import React from "react";
import "./HomeTile.css";

export default ({ src, hoverSrc, title }) => (
  <div className="HomeTile">
    <div
      className="HomeTile__Image"
      style={{
        backgroundImage: `url(${src})`
      }}
    />
    <div className="HomeTile__Info">
      <div className="HomeTile__Title">{title}</div>
      <div className="HomeTile__Arrow">
        <img src="/img/icon-arrow.svg" alt="Open" />
      </div>
    </div>
  </div>
);
